<template>
  <div class="bundle-items">
    <div class="bundle-items-title-container">
      <p class="bundle-items-title-container-title">ITEMS</p>
    </div>
    <contentsListing :listContent="bundleItemsList" :content="content"></contentsListing>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";

export default {
  props: {
    content: {
      type: Object
    }
  },
  data() {
    return {
      bundleItemsList: null
    };
  },
  mounted() {
    this.getItemsContents();
  },
  created() {
    eventBus.$on("bundle-item-response", response => {
      this.bundleItemsList = response.data;
      // console.log("bundleItemsList", this.bundleItemsList);
    });
  },
  methods: {
    getItemsContents() {
      eventBus.$emit("get-bundle-items", this.content.itemlist);
    }
  },
  components: {
    contentsListing: () =>
      import(
        /* webpackChunkName: "contentsListing" */ "@/components/Templates/contentsListing.vue"
      )
  }
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";

.bundle-items {
  &-title-container {
    padding: 20px;
    background: red;
    border-top-left-radius: 0.6rem;
    border-top-right-radius: 0.6rem;
    background-color: rgba(24, 24, 24, 1);
    &-title {
      opacity: 0.5;
      color: #ffffff;
      font-family: $font-regular;
      font-size: 18px;
      font-weight: bold;
    }
  }
}
</style>